import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/sensitive-gr.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/sensitive-mobile-gr.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> SENSITIVE
                    </h1>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="face-cream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-sensitive-face-cream.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin® Sensitive Face Cream Αντηλιακη κρεμα Προσωπου
                        για Ευαισθητες Επιδερμιδες
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Αντηλιακό προσώπου Piz Buin® Sun Sensitive Skin SPF
                          50+ με πολύ υψηλή προστασία. Αναπτύχθηκε σε συνεργασία
                          με δερματολόγους για την προστασία της ευαίσθητης
                          επιδερμίδας.
                          <br />
                          Τριπλή Αντίσταση:
                        </p>
                        <p>
                          • Περιέχει προηγμένα φίλτρα UVA/UVB
                          <br />
                          •Συμβάλλει στην προστασία της επιδερμίδας από
                          επιθετικούς παράγοντες, όπως χλωρίνη, θαλασσινό &amp;
                          αλμυρό νερό
                          <br />• Αδιάβροχο και ανθεκτικό στον ιδρώτα
                        </p>
                        <p>
                          Επίσης, προστατεύει την ακτινοβολία UVA για μεγάλη
                          διάρκεια κι ενυδατώνει και διατηρεί απαλή την
                          ευαίσθητη επιδερμίδα του προσώπου
                          <br />
                          *In vitro test
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Εφαρμόστε ομοιόμορφα μια γενναιόδωρη ποσότητα πριν την
                          έκθεση στον ήλιο. Μειωμένη ποσότητα μειώνει σημαντικά
                          το επίπεδο προστασίας. Επαναλάβετε συχνά την εφαρμογή,
                          ειδικά μετά από εφίδρωση, κολύμπι και σκούπισμα με
                          πετσέτα. Αποφύγετε το μεσημεριανό ήλιο και την
                          παρατεταμένη παραμονή ακόμα και με τη χρήση
                          αντηλιακού. Κρατήστε βρέφη και παιδιά μακριά από την
                          απευθείας έκθεση στην άμεση ακτινοβολία του ηλίου
                        </p>
                      </div>
                    </div>
                  </div>                  
                  <div className="products-page__product clearfix">
                    <a name="skin-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-sensitive-skin-lotion.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin® Sensitive Skin Lotion Αντηλιακo Γαλακτωμα για
                        Ευαισθητες Επιδερμιδες{" "}
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Η λοσιόν σώματος Piz Buin® Sensitive Skin SPF 50+ με
                          Πολύ Υψηλή Προστασία αναπτύχθηκε σε συνεργασία με
                          δερματολόγους για την προστασία της επιδερμίδας με
                          ευαισθησία στον ήλιο.
                          <br />
                          Τριπλή αντίσταση:
                        </p>
                        <p>
                          • Περιέχει προηγμένα φίλτρα UVA/UVB
                          <br />
                          •Συμβάλλει στην προστασία της επιδερμίδας από
                          επιθετικούς παράγοντες, όπως χλωρίνη, θαλασσινό &amp;
                          αλμυρό νερό
                          <br />• Αδιάβροχο και ανθεκτικό στον ιδρώτα
                        </p>
                        <p>
                          Προσφέρει προστασία από τη UVA ακτινοβολία μεγάλης
                          διάρκειας και γρήγορη απορροφητικότητα, χωρίς να
                          αφήνει λευκά σημάδια. Μη λιπαρή σύνθεση που δεν
                          κολλάει.
                          <br />
                          *In vitro test
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF</h4>
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Ανακινήστε πριν τη χρήση. Εφαρμόστε ομοιόμορφα μια
                          γενναιόδωρη ποσότητα πριν την έκθεση στον ήλιο.
                          Μειωμένη ποσότητα μειώνει σημαντικά το επίπεδο
                          προστασίας. Επαναλάβετε συχνά την εφαρμογή, ειδικά
                          μετά από εφίδρωση, κολύμπι και σκούπισμα με πετσέτα.
                          Αποφύγετε το μεσημεριανό ήλιο και την παρατεταμένη
                          παραμονή ακόμα και με τη χρήση αντηλιακού. Κρατήστε
                          βρέφη και παιδιά μακριά από την απευθείας έκθεση στην
                          άμεση ακτινοβολία του ηλίου.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/hydro-infusion/" target="_self">
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/gr/our-products/sensitive/"
                      target="_self"
                    >
                      Sensitive
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
